<template>
  <div>
    <list-template
        :loading="loading"
        :total="total"
        :current-page="page"
        :table-data="tableData"
        :table-config="tableConfig"
        @onChangePage="changeCurrentPage"
        @onSearch="onSearch"
        @onHandle="tableHandle"
        @onReset="search = null;"
        ref="table"
    >
      <template slot="title">
        <div class="flex flex-align flex-between title mb-20" style="padding-right: 40rem">
          <div class="flex flex-align">
            <p><span>检查年月</span><i>{{ info.month }}</i></p>
            <p><span>检查习惯</span><i>{{ info.knack_info }}</i></p>
            <p><span>检查老师</span><i>{{ info.teacher_info }}</i></p>
            <p><span>检查科目</span><i>{{ info.subject_info }}</i></p>
            <p><span>检查班级</span><i>{{ info.teaching_info }}</i></p>
          </div>
          <el-form ref="form" :model="search" label-width="0">
            <div class="flex flex-align">
              <el-form-item style="margin-right: 20rem">
                <el-select size="small" placeholder="请选择检查状态" v-model="search.status" :popper-append-to-body="false">
                  <el-option v-for="option in statusType" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-right: 20rem">
                <el-input v-model="search.keyword" size="small" placeholder="请输入学生姓名搜索"></el-input>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <el-button debounce size="small" style="margin-left: 16rem" @click.prevent="onReset">重置</el-button>
            </div>
          </el-form>
        </div>
      </template>
    </list-template>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      loading: true,
      // 搜索框的选中数据
      search: {
				// status: 0,
        keyword: ''
      },
      // 数据总量
      total: 0,
      statusType: [
        {label: "已检查", value: 1},
        {label: "未检查", value: 2}
      ],
      tableData: [],
      info: {},
      tableConfig: [
        {width: "80rem", label: '序号', type: 'number', textAlign: 'left'},
        {prop: "student_name", label: "学生姓名",},
        {prop: "student_no", label: "学号"},
        {prop: "class_info", label: "行政班"},
        {prop: "check_status", label: "检查状态",},
        {prop: "check_time", label: "检查时间",},
        {
          width: 120,
          label: "操作",
          handle: true,
          showOverflowTooltip: false,
          render: (row) => {
            return ["查看"]
          }
        },
      ],
    }
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData(); // 获取数据
  },
  methods: {
    ...mapMutations(["setPage"]),
    // 获取列表基础数据
		getData() {
			this.loading = true
			this.$_axios2.get("api/educational-routine/wont-detail-student-list", {
				params: {
					task_id: this.$route.query.id,
					page: this.page,
					status: this.search.status ? this.search.status : 0,
					keyword: this.search.keyword
				}
			}).then(res => {
				let data = res.data.data
				this.tableData = data.list;
				this.total = data.page.total;
			}).finally(() => {
				this.loading = false
			})

			this.$_axios2.get("api/educational-routine/wont-detail-info?task_id=" + this.$route.query.id).then(res => {
				this.info = res.data.data
			})
		},
		changeCurrentPage(e) {
			this.setPage(e)
			this.getData();
		},
		onSearch() {
			this.setPage(1);
			// this.search = val
			this.getData();
		},
		onReset() {
			this.search = {
				keyword: ''
			}
			this.setPage(1);
			this.getData();
		},
		// 表格后面操作被点击
		tableHandle(row, text) {
			this.$router.push({path: "/edu/examine/student/details?id=" + row.content_id});
		},
	}
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1140rem) {
  ::v-deep .el-form .el-input {
    width: auto !important;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

::v-deep .t-input .el-input {
  width: 200rem !important;
}

::v-deep .t-input .el-select .el-input {
  width: 10.55vw !important;
}

.title {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);

  p {
    margin-right: 20rem;
    white-space:nowrap;
    display: flex;
  }

  i{
    white-space:nowrap;
  }

  span {
    color: rgba(0, 0, 0, .8);
    margin-right: 10rem;
    white-space:nowrap;
  }
}
</style>
